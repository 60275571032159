import React, { useContext, useRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import CircleIcon from '@mui/icons-material/Circle';
import DoneRoundedIcon from '@mui/icons-material/DoneRounded';
import { Divider, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';

import { MoreMenu, MyAvatar } from 'components';
import {
  highlighParagraphViaCommentTag,
  getFormattedDateAndTime,
  MESSAGE_TYPES,
  getElementsWithoutCommentClasses
} from 'helpers';
import { UserContext, WebSocketConnectionContext } from 'providers';

import { MenuItems } from './MenuItems';

export const CommentInfo = ({
  comment,
  quillEditor,
  selectedCommentId,
  isFadeReply,
  scrollRef,
  setSelectedCommentTag,
  paragraphLevelCommentIdsRef
}) => {
  const theme = useTheme();
  const { user } = useContext(UserContext);
  const { connection } = useContext(WebSocketConnectionContext);
  const [replyHeight, setReplyHeight] = useState(0);
  const MAX_REPLY_HEIGHT = 50;
  const replyRef = useRef();

  const [t] = useTranslation('common');

  const closedCommentCard =
    !selectedCommentId ||
    (selectedCommentId !== comment.id && selectedCommentId !== comment.parentCommentId);

  const isCommentCreator = comment.createdBy === user.id;

  useEffect(() => {
    if (isFadeReply) {
      setReplyHeight(replyRef?.current?.clientHeight);
    }
  }, [isFadeReply]);

  const useStyles = makeStyles(() => ({
    moreMenuStyle: {
      position: 'static'
    }
  }));
  const classes = useStyles();

  const closedCommentCardStyle = {
    width: '100%',
    position: 'relative',
    '&:after': {
      content: '""',
      position: 'absolute',
      zIndex: 1,
      bottom: 0,
      left: 0,
      pointerEvents: 'none',
      backgroundImage: 'linear-gradient(to bottom, rgba(255,255,255,0), rgba(255,255,255, 1) 90%)',
      width: '100%',
      height: '48px'
    }
  };

  const closedCommentTextStyle = {
    width: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: '3',
    WebkitBoxOrient: 'vertical'
  };

  const removeCommentHighlightClass = () => {
    highlighParagraphViaCommentTag(`comment-${comment.id}`, 'remove');
    setSelectedCommentTag(-1);
    paragraphLevelCommentIdsRef.current = [];
  };

  const findElementsWithCommentId = () => {
    const htmlCollection = document.getElementsByClassName(`comment-${comment.id}`);
    return htmlCollection.length
      ? htmlCollection
      : document.getElementsByClassName(`comment-${comment.parentCommentId}`);
  };

  //when comment is deleted from the sidebar, based on id, class attribute with id is found and removed from text.
  const removeCommentTagFromText = () => {
    removeCommentHighlightClass();
    const elements = findElementsWithCommentId();
    getElementsWithoutCommentClasses(elements, comment.id);
  };

  const manageCommentClassAttributes = message => {
    switch (message) {
      case MESSAGE_TYPES.REMOVE_COMMENTS:
        removeCommentTagFromText();
        break;
      case MESSAGE_TYPES.RESOLVE_COMMENT:
        removeCommentHighlightClass();
    }
  };

  const performCommentOperation = message => {
    connection
      ?.invoke(message, message === MESSAGE_TYPES.REMOVE_COMMENTS ? [comment.id] : comment.id)
      .then(() => {
        manageCommentClassAttributes(message);
      })
      .catch(err => {
        // eslint-disable-next-line no-console
        console.log(err);
      });
  };

  const actionMenu = () => {
    return isCommentCreator || comment.resolved ? (
      <Stack onClick={e => e.stopPropagation()}>
        <MoreMenu vertical='bottom' customStyles={classes.moreMenuStyle}>
          <MenuItems
            isResolved={comment.resolved}
            isCommentCreator={isCommentCreator}
            performCommentOperation={performCommentOperation}
          />
        </MoreMenu>
      </Stack>
    ) : (
      !comment.parentCommentId && (
        <Stack onClick={e => e.stopPropagation()}>
          <Tooltip title={t('markCommentAsResolved')} placement='top'>
            <IconButton onClick={() => performCommentOperation(MESSAGE_TYPES.RESOLVE_COMMENT)}>
              <DoneRoundedIcon
                sx={{ width: '20px', height: '20px', color: theme.palette.active.dark }}
              />
            </IconButton>
          </Tooltip>
        </Stack>
      )
    );
  };

  const displayCircleIcon = () => {
    return (
      !isCommentCreator &&
      !comment.resolved && (
        <CircleIcon
          sx={{
            width: '6px',
            height: '6px',
            color: theme.palette.primary.main
          }}
        />
      )
    );
  };

  const displayReplies = () => {
    return selectedCommentId === comment.id ? (
      comment?.replies?.map(reply => {
        return (
          <div key={reply.id}>
            <Divider sx={{ my: '15px' }} />
            <CommentInfo
              comment={reply}
              selectedCommentId={selectedCommentId}
              scrollRef={scrollRef}
              quillEditor={quillEditor}
              setSelectedCommentTag={setSelectedCommentTag}
              paragraphLevelCommentIdsRef={paragraphLevelCommentIdsRef}
            />
          </div>
        );
      })
    ) : (
      <div>
        <Divider sx={{ my: '15px' }} />
        <CommentInfo
          comment={comment?.replies[0]}
          isFadeReply={true}
          quillEditor={quillEditor}
          selectedCommentId={selectedCommentId}
          scrollRef={scrollRef}
          setSelectedCommentTag={setSelectedCommentTag}
          paragraphLevelCommentIdsRef={paragraphLevelCommentIdsRef}
        />
      </div>
    );
  };

  const checkIfResolved = () => (comment.resolved ? '5px' : '10px');

  const replaceMentions = comment => {
    //regex for markup mentions finds all instances of @[displayName](id) and pulls only a displayName
    const mentionRegex = /@\[([^\]]+)\]\(\d+\)/g;
    return comment.content.split(mentionRegex).map((part, index) => {
      const uniqueId = comment.id + index;
      if (index % 2 === 1) {
        // This is the mention part and only displayName is shown
        return (
          <span style={{ color: theme.palette.active.dark }} key={uniqueId}>
            {part}
          </span>
        );
      }
      // This is the text part
      return part;
    });
  };

  return (
    <Stack
      sx={[
        { width: '100%' },
        closedCommentCard && replyHeight > MAX_REPLY_HEIGHT && closedCommentCardStyle
      ]}>
      <Stack spacing={'10px'} ref={el => (scrollRef.current[comment.id] = el)}>
        <Stack
          direction='row'
          spacing={2}
          sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Stack direction='row' spacing={1} sx={{ display: 'flex', alignItems: 'center' }}>
            {!comment.parentCommentId && displayCircleIcon()}
            <MyAvatar
              sx={{
                width: 32,
                height: 32,
                position: 'static',
                '&.MuiAvatar-root': {
                  ml: isCommentCreator ? '0px' : checkIfResolved()
                }
              }}
              displayName={comment.displayName}
              opacity
            />
            <Stack>
              <Typography
                sx={{
                  fontFamily: theme.typography.fontFamilyPrimaryRegular,
                  fontSize: theme.typography.pxToRem(12),
                  color: theme.palette.primary.main,
                  fontWeight: 500
                }}>
                {comment.displayName}
              </Typography>
              <Typography
                sx={{
                  fontFamily: theme.typography.fontFamilyPrimaryRegular,
                  fontSize: theme.typography.pxToRem(10),
                  fontWeight: theme.typography.fontWeightRegular,
                  color: theme.palette.gray.main
                }}>
                {getFormattedDateAndTime(comment.createdAt)}
              </Typography>
            </Stack>
          </Stack>
          {actionMenu()}
        </Stack>
        <Typography
          ref={replyRef}
          sx={[
            {
              fontFamily: theme.typography.fontFamilyPrimaryRegular,
              fontSize: theme.typography.pxToRem(12),
              fontWeight: theme.typography.fontWeightRegular,
              color: theme.palette.primary.main,
              whiteSpace: 'pre-line',
              mb: '10px !important'
            },
            closedCommentCard && closedCommentTextStyle
          ]}>
          {replaceMentions(comment)}
        </Typography>
      </Stack>
      {comment?.replies?.length > 0 && displayReplies()}
    </Stack>
  );
};

CommentInfo.propTypes = {
  comment: PropTypes.object,
  quillEditor: PropTypes.object,
  selectedCommentId: PropTypes.string,
  isFadeReply: PropTypes.bool,
  scrollRef: PropTypes.object,
  setSelectedCommentTag: PropTypes.func,
  paragraphLevelCommentIdsRef: PropTypes.object
};
