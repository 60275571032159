import React from 'react';

import PropTypes from 'prop-types';

import { ReactComponent as AcceptCircle } from 'resources/images/acceptCircle.svg';
import { ReactComponent as AccountIcon } from 'resources/images/account.svg';
import { ReactComponent as AccountSettingsIcon } from 'resources/images/accountSettings.svg';
import { ReactComponent as AccountSettingsLabelsIcon } from 'resources/images/accountSettingsLabels.svg';
import { ReactComponent as AccountSettingsUsersListIcon } from 'resources/images/accountSettingsUsersList.svg';
import { ReactComponent as AddCommentIcon } from 'resources/images/addComment.svg';
import { ReactComponent as AlignCenterIcon } from 'resources/images/alignmentCenter.svg';
import { ReactComponent as AlignJustifyIcon } from 'resources/images/alignmentJustify.svg';
import { ReactComponent as AlignLeftIcon } from 'resources/images/alignmentLeft.svg';
import { ReactComponent as AlignRightIcon } from 'resources/images/alignmentRight.svg';
import { ReactComponent as AppliedParagraphSuggestionIcon } from 'resources/images/appliedParagraphSuggestion.svg';
import { ReactComponent as AppliedReplacementIcon } from 'resources/images/appliedReplacement.svg';
import { ReactComponent as ApplySuggestionIcon } from 'resources/images/applySuggestion.svg';
import { ReactComponent as ArrowBackwardIcon } from 'resources/images/arrowBackward.svg';
import { ReactComponent as ArrowDownIcon } from 'resources/images/arrowDown.svg';
import { ReactComponent as ArrowForwardIcon } from 'resources/images/arrowForward.svg';
import { ReactComponent as ArrowRightIcon } from 'resources/images/arrowRight.svg';
import { ReactComponent as AuthIcon } from 'resources/images/authIcon.svg';
import { ReactComponent as AverageScoreIcon } from 'resources/images/averageScore.svg';
import { ReactComponent as BackIcon } from 'resources/images/back.svg';
import { ReactComponent as BadScoreIcon } from 'resources/images/badScore.svg';
import { ReactComponent as BaselineReadabilityScoreGraphic } from 'resources/images/baselineReadabilityScoreGraphic.svg';
import { ReactComponent as BlackPen } from 'resources/images/blackPen.svg';
import { ReactComponent as BlackTick } from 'resources/images/blackTick.svg';
import { ReactComponent as BoldIcon } from 'resources/images/bold.svg';
import { ReactComponent as CalendarIcon } from 'resources/images/calendar.svg';
import { ReactComponent as ChangeCategoryIcon } from 'resources/images/changeCategory.svg';
import { ReactComponent as CheckboxCheckedIcon } from 'resources/images/checkboxChecked.svg';
import { ReactComponent as CheckboxUncheckedIcon } from 'resources/images/checkboxUnchecked.svg';
import { ReactComponent as CheckedIcon } from 'resources/images/checked.svg';
import { ReactComponent as CheckedBlueIcon } from 'resources/images/checkedBlue.svg';
import { ReactComponent as CheckedGreenIcon } from 'resources/images/checkedGreen.svg';
import { ReactComponent as CheckedGreenRoundedIcon } from 'resources/images/checkedGreenRounded.svg';
import { ReactComponent as CheckedGreyIcon } from 'resources/images/checkedGrey.svg';
import { ReactComponent as CheckInGreenCircle } from 'resources/images/checkInGreenCircle.svg';
import { ReactComponent as CircleExclamationIcon } from 'resources/images/circleExclamation.svg';
import { ReactComponent as CircleXMarkIcon } from 'resources/images/circleXMark.svg';
import { ReactComponent as CleanTextIcon } from 'resources/images/cleanText.svg';
import { ReactComponent as CollapseIcon } from 'resources/images/collapseIcon.svg';
import { ReactComponent as CollapseRightIcon } from 'resources/images/collapseIconRight.svg';
import { ReactComponent as CollapseSuggestionIcon } from 'resources/images/collapseSuggestion.svg';
import { ReactComponent as ComingSoonIcon } from 'resources/images/comingSoon.svg';
import { ReactComponent as CommentingCursorIcon } from 'resources/images/commentingCursor.svg';
import { ReactComponent as CommentsIcon } from 'resources/images/comments.svg';
import { ReactComponent as CopyIcon } from 'resources/images/copy.svg';
import { ReactComponent as CorruptDocumentIcon } from 'resources/images/corruptDocumentIcon.svg';
import { ReactComponent as CreateLabelIcon } from 'resources/images/createLabel.svg';
import { ReactComponent as CurrentReadabilityScoreGraphic } from 'resources/images/currentReadabilityScoreGraphic.svg';
import { ReactComponent as DashboardWelcomeIcon } from 'resources/images/dashboardWelcomeIcon.svg';
import { ReactComponent as DeleteIcon } from 'resources/images/delete.svg';
import { ReactComponent as DeleteExclamationMark } from 'resources/images/deleteExclamationMark.svg';
import { ReactComponent as DeleteTableElement } from 'resources/images/deleteTableElement.svg';
import { ReactComponent as DocumentBackIcon } from 'resources/images/documentBack.svg';
import { ReactComponent as DocumentCreatedIcon } from 'resources/images/documentCreated.svg';
import { ReactComponent as DocumentFileIcon } from 'resources/images/documentFile.svg';
import { ReactComponent as DocumentFilterIcon } from 'resources/images/documentFilter.svg';
import { ReactComponent as DocumentFilterIconDark } from 'resources/images/documentFilterDark.svg';
import { ReactComponent as DocumentManagementIcon } from 'resources/images/documentManagement.svg';
import { ReactComponent as DownloadReportIcon } from 'resources/images/downloadReport.svg';
import { ReactComponent as EditIcon } from 'resources/images/edit.svg';
import { ReactComponent as EditLabelsIcon } from 'resources/images/editLabels.svg';
import { ReactComponent as EditorPenIcon } from 'resources/images/editorPen.svg';
import { ReactComponent as EmailIcon } from 'resources/images/email.svg';
import { ReactComponent as EnvelopeIcon } from 'resources/images/envelopeIcon.svg';
import { ReactComponent as Error404Icon } from 'resources/images/error404.svg';
import { ReactComponent as Error500Icon } from 'resources/images/error500.svg';
import { ReactComponent as ExclamationTriangleIcon } from 'resources/images/exclamationTriangle.svg';
import { ReactComponent as ExpandIcon } from 'resources/images/expand.svg';
import { ReactComponent as ExpandSuggestionIcon } from 'resources/images/expandSuggestion.svg';
import { ReactComponent as ExportIcon } from 'resources/images/export.svg';
import { ReactComponent as EyeIcon } from 'resources/images/eyeIcon.svg';
import { ReactComponent as FadedLabelIcon } from 'resources/images/fadedLabel.svg';
import { ReactComponent as FileIcon } from 'resources/images/file.svg';
import { ReactComponent as FirstStepIcon } from 'resources/images/firstStep.svg';
import { ReactComponent as FlagIcon } from 'resources/images/flag.svg';
import { ReactComponent as GoodScoreIcon } from 'resources/images/goodScore.svg';
import { ReactComponent as GrayBackIcon } from 'resources/images/grayBack.svg';
import { ReactComponent as GrayPenIcon } from 'resources/images/grayPen.svg';
import { ReactComponent as GrayQuestionMarkIcon } from 'resources/images/grayQuestionMark.svg';
import { ReactComponent as GraySearchIcon } from 'resources/images/graySearch.svg';
import { ReactComponent as GreenPlusIcon } from 'resources/images/greenPlus.svg';
import { ReactComponent as GreenShallowLabelIcon } from 'resources/images/greenShallowLabel.svg';
import { ReactComponent as GreenTickIcon } from 'resources/images/greenTick.svg';
import { ReactComponent as IndentationLeftIcon } from 'resources/images/indentationLeft.svg';
import { ReactComponent as IndentationRightIcon } from 'resources/images/indentationRight.svg';
import { ReactComponent as InsertTableIcon } from 'resources/images/insertTable.svg';
import { ReactComponent as InsertTableElement } from 'resources/images/insertTableElement.svg';
import { ReactComponent as InsertTableExpander } from 'resources/images/insertTableExpander.svg';
import { ReactComponent as IntelligibilityFilterIcon } from 'resources/images/intelligibilityFilterIcon.svg';
import { ReactComponent as InvisibleSuggestionIcon } from 'resources/images/invisibleSuggestionIcon.svg';
import { ReactComponent as ItalicIcon } from 'resources/images/italic.svg';
import { ReactComponent as LabelsIcon } from 'resources/images/labels.svg';
import { ReactComponent as LinkIcon } from 'resources/images/link.svg';
import { ReactComponent as ListOrderedIcon } from 'resources/images/listOrdered.svg';
import { ReactComponent as ListUnorderedIcon } from 'resources/images/listUnordered.svg';
import { ReactComponent as LoadingIcon } from 'resources/images/loading.svg';
import { ReactComponent as LogoIcon } from 'resources/images/logo.svg';
import { ReactComponent as LogoForLoaderIcon } from 'resources/images/logoForLoader.svg';
import { ReactComponent as LogoPinkIcon } from 'resources/images/logoPink.svg';
import { ReactComponent as LogoutIcon } from 'resources/images/logout.svg';
import { ReactComponent as LogoWithoutTextIcon } from 'resources/images/logoWithoutText.svg';
import { ReactComponent as LogoWithText } from 'resources/images/logoWithText.svg';
import { ReactComponent as MetricsPopupIcon } from 'resources/images/metricsPopup.svg';
import { ReactComponent as MoreHorizontalFill } from 'resources/images/moreHorizontalFill.svg';
import { ReactComponent as MoreVerticalFill } from 'resources/images/moreVerticalFill.svg';
import { ReactComponent as MovingArrowDownIcon } from 'resources/images/movingArrowDown.svg';
import { ReactComponent as MovingArrowUpIcon } from 'resources/images/movingArrowUp.svg';
import { ReactComponent as NoCommentsAdded } from 'resources/images/noCommentsAdded.svg';
import { ReactComponent as NoComplexityIndicator } from 'resources/images/noComplexityIndicator.svg';
import { ReactComponent as NoResultIcon } from 'resources/images/noResultIcon.svg';
import { ReactComponent as NoSimplifications } from 'resources/images/noSimplifications.svg';
import { ReactComponent as OriginalDocumentIcon } from 'resources/images/originalDocument.svg';
import { ReactComponent as PadlockIcon } from 'resources/images/padlockIcon.svg';
import { ReactComponent as ParagraphSuggestionHintBoxIcon } from 'resources/images/paragraphSuggestionHintBox.svg';
import { ReactComponent as PartyPopperIcon } from 'resources/images/partyPopper.svg';
import { ReactComponent as PasswordCorrectIcon } from 'resources/images/passwordCorrectIcon.svg';
import { ReactComponent as PasswordIncorrectIcon } from 'resources/images/passwordIncorrectIcon.svg';
import { ReactComponent as PasswordInvisibleIcon } from 'resources/images/passwordInvisibleIcon.svg';
import { ReactComponent as PasswordVisibleIcon } from 'resources/images/passwordVisibleIcon.svg';
import { ReactComponent as PreviewVersionIcon } from 'resources/images/previewVersion.svg';
import { ReactComponent as ReadabilityImprovementGraphic } from 'resources/images/readabilityImprovementGraphic.svg';
import { ReactComponent as ReadabilityRecalculation } from 'resources/images/readabilityRecalculation.svg';
import { ReactComponent as RedoIcon } from 'resources/images/redo.svg';
import { ReactComponent as RejectSuggestionIcon } from 'resources/images/rejectSuggestion.svg';
import { ReactComponent as ReloadIcon } from 'resources/images/reload.svg';
import { ReactComponent as ReloadSuggestionsIcon } from 'resources/images/reloadSuggestionsIcon.svg';
import { ReactComponent as RemoveLabelsIcon } from 'resources/images/removeLabels.svg';
import { ReactComponent as RenameIcon } from 'resources/images/rename.svg';
import { ReactComponent as RephraseIcon } from 'resources/images/rephrase.svg';
import { ReactComponent as ReportsIcon } from 'resources/images/reports.svg';
import { ReactComponent as ResetAuthenticationIcon } from 'resources/images/resetAuthentication.svg';
import { ReactComponent as ResetFiltersIcon } from 'resources/images/resetFilters.svg';
import { ReactComponent as RestoreIcon } from 'resources/images/restore.svg';
import { ReactComponent as RoundExitIcon } from 'resources/images/roundExit.svg';
import { ReactComponent as SaveDocument } from 'resources/images/saveDocument.svg';
import { ReactComponent as SaveFilterSet } from 'resources/images/saveFilterSet.svg';
import { ReactComponent as SearchIcon } from 'resources/images/search.svg';
import { ReactComponent as SecondStepIcon } from 'resources/images/secondStep.svg';
import { ReactComponent as SendIcon } from 'resources/images/sendIcon.svg';
import { ReactComponent as SentenceSuggestionHintBoxIcon } from 'resources/images/sentenceSuggestionHintBox.svg';
import { ReactComponent as SeparatorIcon } from 'resources/images/separator.svg';
import { ReactComponent as SimplificationIcon } from 'resources/images/simplification.svg';
import { ReactComponent as SimplifyTextIcon } from 'resources/images/simplifyText.svg';
import { ReactComponent as SuccessIcon } from 'resources/images/success.svg';
import { ReactComponent as SuccessAuthIcon } from 'resources/images/successAuth.svg';
import { ReactComponent as SuggestionsSpinner } from 'resources/images/suggestionsSpinner.svg';
import { ReactComponent as TableIcon } from 'resources/images/table.svg';
import { ReactComponent as TeamIcon } from 'resources/images/teamIcon.svg';
import { ReactComponent as UnderlineIcon } from 'resources/images/underline.svg';
import { ReactComponent as UnderMaintenanceIcon } from 'resources/images/underMaintenance.svg';
import { ReactComponent as UndoIcon } from 'resources/images/undo.svg';
import { ReactComponent as UploadedVersionIcon } from 'resources/images/uploadedVersionIcon.svg';
import { ReactComponent as UploadInProgressIcon } from 'resources/images/uploadInProgressIcon.svg';
import { ReactComponent as UploadSuccessIcon } from 'resources/images/uploadSuccessIcon.svg';
import { ReactComponent as UploadVersionIcon } from 'resources/images/uploadVersion.svg';
import { ReactComponent as UserFilterIcon } from 'resources/images/userFilterIcon.svg';
import { ReactComponent as UserIcon } from 'resources/images/userIcon.svg';
import { ReactComponent as UsersListIcon } from 'resources/images/userList.svg';
import { ReactComponent as VersionHistoryIcon } from 'resources/images/versionHistory.svg';
import { ReactComponent as ViewEditsIcon } from 'resources/images/viewEdits.svg';
import { ReactComponent as VisibilityIcon } from 'resources/images/visibility.svg';
import { ReactComponent as VisibleSuggestionIcon } from 'resources/images/visibleSuggestionIcon.svg';
import { ReactComponent as WalletIcon } from 'resources/images/wallet.svg';
import { ReactComponent as WhiteTickIcon } from 'resources/images/whiteTick.svg';
import { ReactComponent as WhiteTickSmallIcon } from 'resources/images/whiteTickSmall.svg';
import { ReactComponent as XMark } from 'resources/images/xMark.svg';

export const Icons = ({ iconName, strokeColor = '', fill = '#83909b', height, width }) => {
  const icons = {
    acceptCircle: <AcceptCircle fill={fill} />,
    accountIcon: <AccountIcon fill={fill} />,
    accountSettings: <AccountSettingsIcon />,
    accountSettingsLabels: <AccountSettingsLabelsIcon />,
    accountSettingsUsersList: <AccountSettingsUsersListIcon />,
    addComment: <AddCommentIcon />,
    alignCenter: <AlignCenterIcon />,
    alignJustify: <AlignJustifyIcon />,
    alignLeft: <AlignLeftIcon />,
    alignRight: <AlignRightIcon />,
    appliedParagraphSuggestion: <AppliedParagraphSuggestionIcon />,
    appliedReplacement: <AppliedReplacementIcon />,
    applySuggestion: <ApplySuggestionIcon />,
    arrowBackward: <ArrowBackwardIcon />,
    arrowDown: <ArrowDownIcon />,
    arrowForward: <ArrowForwardIcon />,
    arrowRightIcon: <ArrowRightIcon fill={fill} />,
    auth: <AuthIcon />,
    averageScoreIcon: <AverageScoreIcon data-testid='averageScoreIcon' />,
    backIcon: <BackIcon />,
    badScoreIcon: <BadScoreIcon data-testid='badScoreIcon' />,
    baselineReadabilityScoreGraphic: <BaselineReadabilityScoreGraphic />,
    blackPen: <BlackPen />,
    blackTick: <BlackTick />,
    bold: <BoldIcon />,
    calendar: <CalendarIcon fill={fill} />,
    changeCategory: <ChangeCategoryIcon />,
    checkboxCheckedIcon: (
      <CheckboxCheckedIcon data-testid='checkboxCheckedIcon' width={width} height={height} />
    ),
    checkboxUncheckedIcon: (
      <CheckboxUncheckedIcon
        data-testid='checkboxUncheckedIcon'
        width={width}
        height={height}
        fill={fill}
        stroke={strokeColor}
      />
    ),
    checkedBlueIcon: <CheckedBlueIcon />,
    checkedGreenIcon: <CheckedGreenIcon />,
    checkedGreyIcon: <CheckedGreyIcon />,
    checkInGreenCircle: <CheckInGreenCircle />,
    checkedGreenRounded: <CheckedGreenRoundedIcon />,
    checkedIcon: <CheckedIcon />,
    circleExclamation: (
      <CircleExclamationIcon
        height={height}
        width={width}
        fill={fill}
        data-testid='circleExclamation'
      />
    ),
    circleXMark: <CircleXMarkIcon />,
    cleanText: <CleanTextIcon />,
    collapseIcon: <CollapseIcon />,
    collapseRightIcon: <CollapseRightIcon data-testid='collapseRightIcon' />,
    collapseSuggestion: <CollapseSuggestionIcon />,
    comingSoonIcon: <ComingSoonIcon />,
    commentsIcon: <CommentsIcon fill={fill} />,
    commentingCursor: <CommentingCursorIcon />,
    copy: <CopyIcon height={height} width={width} fill={fill} />,
    corruptDocument: <CorruptDocumentIcon />,
    createLabelIcon: <CreateLabelIcon fill={fill} />,
    currentReadabilityScoreGraphic: <CurrentReadabilityScoreGraphic />,
    dashboardWelcomeIcon: <DashboardWelcomeIcon />,
    delete: <DeleteIcon />,
    deleteExclamationMark: <DeleteExclamationMark />,
    deleteTableElement: <DeleteTableElement />,
    documentBackIcon: <DocumentBackIcon />,
    documentFile: <DocumentFileIcon />,
    documentCreated: <DocumentCreatedIcon />,
    documentFilterIcon: <DocumentFilterIcon fill={fill} />,
    documentFilterIconDark: <DocumentFilterIconDark />,
    documentManagement: <DocumentManagementIcon fill={fill} />,
    downloadReport: <DownloadReportIcon stroke={strokeColor} />,
    edit: <EditIcon />,
    editLabels: <EditLabelsIcon fill={fill} />,
    editorPenIcon: <EditorPenIcon />,
    email: <EmailIcon />,
    envelopeIcon: <EnvelopeIcon />,
    error404: <Error404Icon />,
    error500: <Error500Icon />,
    exclamationTriangleIcon: <ExclamationTriangleIcon />,
    expand: <ExpandIcon />,
    expandSuggestion: <ExpandSuggestionIcon height={height} width={width} />,
    export: <ExportIcon stroke={strokeColor} />,
    eyeIcon: <EyeIcon />,
    fadedLabel: <FadedLabelIcon />,
    fileIcon: <FileIcon />,
    firstStep: <FirstStepIcon />,
    flag: <FlagIcon />,
    goodScoreIcon: <GoodScoreIcon data-testid='goodScoreIcon' />,
    grayBack: <GrayBackIcon />,
    grayPen: <GrayPenIcon fill={fill} />,
    graySearch: <GraySearchIcon />,
    greenPlus: <GreenPlusIcon />,
    greenShallowLabel: <GreenShallowLabelIcon />,
    greenTick: <GreenTickIcon />,
    grayQuestionMark: <GrayQuestionMarkIcon width={width} height={height} />,
    indentationLeft: <IndentationLeftIcon />,
    indentationRight: <IndentationRightIcon />,
    insertTableElement: <InsertTableElement />,
    insertTableExpander: <InsertTableExpander />,
    insertTableIcon: <InsertTableIcon />,
    intelligibilityFilterIcon: <IntelligibilityFilterIcon />,
    invisibleSuggestion: <InvisibleSuggestionIcon />,
    italic: <ItalicIcon />,
    labels: <LabelsIcon fill={fill} />,
    link: <LinkIcon />,
    listOrdered: <ListOrderedIcon />,
    listUnordered: <ListUnorderedIcon />,
    loadingIcon: <LoadingIcon />,
    logo: <LogoIcon />,
    logoForLoader: <LogoForLoaderIcon />,
    logoPink: <LogoPinkIcon />,
    logout: <LogoutIcon fill={fill} />,
    logoWithText: <LogoWithText />,
    logoWithoutText: <LogoWithoutTextIcon />,
    metricsPopupIcon: <MetricsPopupIcon fill={fill} />,
    moreHorizontalFill: <MoreHorizontalFill />,
    moreVerticalFill: <MoreVerticalFill />,
    movingArrowDown: <MovingArrowDownIcon stroke={strokeColor} />,
    movingArrowUp: <MovingArrowUpIcon stroke={strokeColor} />,
    noCommentsAdded: <NoCommentsAdded />,
    noComplexityIndicator: <NoComplexityIndicator />,
    noResultIcon: <NoResultIcon />,
    noSimplifications: <NoSimplifications />,
    originalDocument: <OriginalDocumentIcon />,
    padlockIcon: <PadlockIcon />,
    paragraphSuggestionHintBox: <ParagraphSuggestionHintBoxIcon />,
    partyPopper: <PartyPopperIcon />,
    passwordCorrect: <PasswordCorrectIcon />,
    passwordIncorrect: <PasswordIncorrectIcon />,
    passwordInvisible: <PasswordInvisibleIcon />,
    passwordVisible: <PasswordVisibleIcon />,
    preview: <PreviewVersionIcon />,
    readabilityImprovementGraphic: <ReadabilityImprovementGraphic />,
    readabilityRecalculation: <ReadabilityRecalculation />,
    redo: <RedoIcon />,
    rejectSuggestion: <RejectSuggestionIcon />,
    reload: <ReloadIcon stroke={strokeColor} />,
    reloadSuggestions: <ReloadSuggestionsIcon />,
    removeLabels: <RemoveLabelsIcon />,
    rename: <RenameIcon />,
    rephrase: <RephraseIcon />,
    reportsIcon: <ReportsIcon />,
    resetAuthenticationIcon: <ResetAuthenticationIcon />,
    resetFiltersIcon: <ResetFiltersIcon />,
    restore: <RestoreIcon />,
    roundExit: <RoundExitIcon />,
    saveDocument: <SaveDocument />,
    saveFilterSet: <SaveFilterSet />,
    searchIcon: <SearchIcon />,
    secondStep: <SecondStepIcon />,
    send: <SendIcon />,
    sentenceSuggestionHintBox: <SentenceSuggestionHintBoxIcon />,
    separator: <SeparatorIcon />,
    simplificationIcon: <SimplificationIcon fill={fill} />,
    simplifyText: <SimplifyTextIcon />,
    success: <SuccessIcon />,
    successAuth: <SuccessAuthIcon />,
    suggestionsSpinner: <SuggestionsSpinner />,
    tableIcon: <TableIcon />,
    teamIcon: <TeamIcon />,
    underline: <UnderlineIcon />,
    underMaintenance: <UnderMaintenanceIcon />,
    undo: <UndoIcon />,
    uploadedVersion: <UploadedVersionIcon />,
    uploadInProgress: <UploadInProgressIcon />,
    uploadSuccess: <UploadSuccessIcon />,
    uploadVersionIcon: <UploadVersionIcon />,
    userIcon: <UserIcon />,
    userFilterIcon: <UserFilterIcon />,
    usersList: <UsersListIcon />,
    versionHistoryIcon: <VersionHistoryIcon />,
    viewEdits: <ViewEditsIcon />,
    visibility: <VisibilityIcon />,
    visibleSuggestion: <VisibleSuggestionIcon />,
    wallet: <WalletIcon />,
    whiteTick: <WhiteTickIcon />,
    whiteTickSmall: <WhiteTickSmallIcon />,
    xMark: <XMark />
  };

  return icons[iconName];
};

Icons.propTypes = {
  iconName: PropTypes.string
};
