import React, { useContext, lazy } from 'react';
import { Navigate, useLocation, useParams, useRoutes } from 'react-router-dom';

import { checkPermission, permissions, lazyRetry } from 'helpers';
import { DashboardLayout } from 'layouts';
import { ReportingAndAnalyticsProvider, SUCCESSFUL_VALIDATION, UserContext } from 'providers';

import { DocumentRedirection } from './DocumentRedirection';
import { Loadable } from './Loadable';

const AccountSettingsPage = Loadable(
  lazy(() => lazyRetry(() => import('../containers/AccountSettingsPage/AccountSettingsPage')))
);
const ComingSoonPage = Loadable(
  lazy(() => lazyRetry(() => import('../containers/ErrorPages/ComingSoonPage')))
);
const RiskPage = Loadable(
  lazy(() => lazyRetry(() => import('../containers/ReportingAndAnalytics/Risk/RiskPage')))
);
const IntelligibilityPage = Loadable(
  lazy(() =>
    lazyRetry(() =>
      import('../containers/ReportingAndAnalytics/Intelligibility/IntelligibilityPage')
    )
  )
);
const ProductivityPage = Loadable(
  lazy(() =>
    lazyRetry(() => import('../containers/ReportingAndAnalytics/Productivity/ProductivityPage'))
  )
);
const DocumentCreatePage = Loadable(
  lazy(() => lazyRetry(() => import('../containers/DocumentCreatePage/DocumentCreatePage')))
);
const DocumentManagementPage = Loadable(
  lazy(() => lazyRetry(() => import('../containers/DocumentManagementPage/DocumentManagementPage')))
);
const ForgotPasswordPage = Loadable(
  lazy(() => lazyRetry(() => import('../containers/authentication/ForgotPasswordPage')))
);
const InternalServerErrorPage = Loadable(
  lazy(() => lazyRetry(() => import('../containers/ErrorPages/InternalServerErrorPage')))
);
const LoginPage = Loadable(
  lazy(() => lazyRetry(() => import('../containers/authentication/LoginPage')))
);
const MFALoginPage = Loadable(
  lazy(() => lazyRetry(() => import('../containers/authentication/MFALoginPage')))
);
const MFARegisterPage = Loadable(
  lazy(() => lazyRetry(() => import('../containers/authentication/MFARegisterPage')))
);
const Page404 = Loadable(lazy(() => lazyRetry(() => import('../containers/ErrorPages/Page404'))));
const PrivacyPolicyPage = Loadable(
  lazy(() => lazyRetry(() => import('../containers/authentication/PrivacyPolicyPage')))
);
const ProfilePage = Loadable(
  lazy(() => lazyRetry(() => import('../containers/ProfilePage/ProfilePage')))
);
const VersionHistoryPage = Loadable(
  lazy(() => lazyRetry(() => import('../containers/VersionHistoryPage/VersionHistoryPage')))
);
const RegisterPage = Loadable(
  lazy(() => lazyRetry(() => import('../containers/authentication/RegisterPage')))
);
const ResetPasswordPage = Loadable(
  lazy(() => lazyRetry(() => import('../containers/authentication/ResetPasswordPage')))
);
const UnderMaintenancePage = Loadable(
  lazy(() => lazyRetry(() => import('../containers/ErrorPages/UnderMaintenancePage')))
);
const UserDashboardPage = Loadable(
  lazy(() => lazyRetry(() => import('../containers/UserDashboardPage/UserDashboardPage')))
);
const StyleGuidePage = Loadable(
  lazy(() => lazyRetry(() => import('../containers/StyleGuidePage/StyleGuidePage')))
);

const DocumentContentRedirection = () => {
  const { id } = useParams();
  return <Navigate to={`/content/${id}`} replace />;
};

let previousPath = '/';

export const getPreviousPage = () => {
  return previousPath;
};

const checkAvailableSettingsRoutes = user => {
  if (checkPermission(user.permissions, permissions.USERS_READ)) {
    return '/settings/users';
  }
  if (checkPermission(user.permissions, permissions.LABELS_READ)) {
    return '/settings/labels';
  }
  if (checkPermission(user.permissions, permissions.SETTINGS_BILLING)) {
    return '/settings/billing';
  }
  return '/';
};

export const checkSubRoutes = user => {
  return [
    checkPermission(
      user.permissions,
      permissions.PRODUCTS_READ,
      permissions.PRODUCTS_UPDATE,
      permissions.PRODUCTS_CREATE
    ) && { path: 'content/create', element: <DocumentCreatePage /> },
    checkPermission(user.permissions, permissions.PRODUCTS_READ) && {
      path: 'content',
      element: <DocumentManagementPage />
    },
    {
      path: '/dashboard',
      element: <UserDashboardPage />
    },
    checkPermission(user.permissions, permissions.ANALYTICS_READ) && {
      path: 'reporting/risk',
      element: (
        <ReportingAndAnalyticsProvider>
          <RiskPage />
        </ReportingAndAnalyticsProvider>
      )
    },
    checkPermission(user.permissions, permissions.ANALYTICS_READ) && {
      path: 'reporting/intelligibility',
      element: (
        <ReportingAndAnalyticsProvider>
          <IntelligibilityPage />
        </ReportingAndAnalyticsProvider>
      )
    },
    checkPermission(user.permissions, permissions.ANALYTICS_READ) &&
      checkPermission(user.permissions, permissions.USERS_READ) && {
        path: 'reporting/productivity',
        element: (
          <ReportingAndAnalyticsProvider>
            <ProductivityPage />
          </ReportingAndAnalyticsProvider>
        )
      },
    checkPermission(user.permissions, permissions.USERS_READ) && {
      path: 'settings/users',
      element: <AccountSettingsPage />
    },
    checkPermission(user.permissions, permissions.LABELS_READ) && {
      path: 'settings/labels',
      element: <AccountSettingsPage />
    },
    checkPermission(user.permissions, permissions.SETTINGS_BILLING) && {
      path: 'settings/billing',
      element: <AccountSettingsPage />
    },
    {
      path: '/settings',
      element: <Navigate to={checkAvailableSettingsRoutes(user)} replace />
    },
    checkPermission(user.permissions, permissions.USERS_CREATE) && {
      path: 'settings/users/invite',
      element: <AccountSettingsPage />
    },
    checkPermission(user.permissions, permissions.USERS_UPDATE) && {
      path: 'settings/users/:id',
      element: <AccountSettingsPage />
    },
    checkPermission(user.permissions, permissions.READABILITY_READ) && {
      path: 'content/:id',
      element: <DocumentRedirection />
    },
    checkPermission(user.permissions, permissions.READABILITY_READ) && {
      path: 'content/:id/history',
      element: <VersionHistoryPage />
    },
    checkPermission(user.permissions, permissions.STYLE_GUIDE_READ) && {
      path: 'style_guide',
      element: <StyleGuidePage />
    },
    { path: 'profile', element: <ProfilePage /> },
    { path: '/reporting', element: <Navigate to={'risk'} replace /> },
    { path: '/documents', element: <Navigate to={'/content'} replace /> },
    { path: '/documents/:id', element: <DocumentContentRedirection /> },
    { path: '/', element: <Navigate to={'dashboard'} replace /> },
    { path: '*', element: <Navigate to={'dashboard'} replace /> }
  ];
};

export const Router = () => {
  const { user } = useContext(UserContext);
  const path = useLocation();
  const localStoragePathname = localStorage.getItem('pathname');

  // when signed in, return user to the last visited page before sign out
  if (!user && path.pathname !== '/signin') {
    previousPath = localStoragePathname ?? path.pathname;
    localStorage.removeItem('pathname');
  }

  const isMFACodeValid = localStorage.getItem(SUCCESSFUL_VALIDATION);

  let routes = [];

  if (user && isMFACodeValid) {
    if (user.privacyPolicyAcknowledged) {
      let subroutes = checkSubRoutes(user);
      subroutes = subroutes.filter(sr => !!sr);

      routes = [
        { path: '404', element: <Page404 /> },
        { path: '500', element: <InternalServerErrorPage /> },
        { path: 'coming_soon', element: <ComingSoonPage /> },
        { path: 'under_maintenance', element: <UnderMaintenancePage /> },
        { path: '/', element: <DashboardLayout />, children: subroutes }
      ];
    } else {
      routes = [
        { path: 'privacy_policy', element: <PrivacyPolicyPage /> },
        { path: '*', element: <Navigate to={'privacy_policy'} replace /> }
      ];
    }
  }

  const loggedOutRoutes = [
    { path: 'signin', element: <LoginPage /> },
    { path: 'setup_account', element: <RegisterPage /> },
    { path: 'reset_password', element: <ResetPasswordPage /> },
    { path: 'forgot_password', element: <ForgotPasswordPage /> },
    { path: '404', element: <Page404 /> },
    { path: '500', element: <InternalServerErrorPage /> },
    { path: 'sso/500', element: <InternalServerErrorPage hideButton={true} /> },
    { path: 'coming_soon', element: <ComingSoonPage /> },
    { path: 'under_maintenance', element: <UnderMaintenancePage /> },
    { path: '/', element: <Navigate to={'signin'} replace /> }
  ];

  if (user && !isMFACodeValid) {
    routes = loggedOutRoutes.concat(
      { path: 'mfa_login', element: <MFALoginPage /> },
      !user.mfaCode && { path: 'mfa_register', element: <MFARegisterPage /> },
      {
        path: '*',
        element: <Navigate to={user.mfaCode ? '/mfa_login' : '/mfa_register'} replace />
      }
    );
  }

  if (!user) {
    routes = loggedOutRoutes.concat({
      path: '*',
      element: <Navigate to={'signin'} replace />
    });
  }

  return useRoutes(routes);
};
