import React, { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { faTag } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton, Menu } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';

import { LightTooltip, LabelMenu } from 'components';

const useStyles = makeStyles(theme => ({
  bulkIcon: {
    backgroundColor: theme.palette.gray.white,
    '&:hover': { backgroundColor: theme.palette.gray.light },
    marginRight: '15px',
    width: '2rem',
    height: '2rem'
  }
}));

export const AddLabelMenu = ({ handleBulkLabelChange }) => {
  const [t] = useTranslation('common');
  const theme = useTheme();
  const classes = useStyles(theme);
  const addMultipleLabelsRef = useRef();
  const [isLabelMenuOpen, setIsLabelMenuOpen] = useState(false);
  const [selectedLabels, setSelectedLabels] = useState([]);

  const handleClose = () => {
    setIsLabelMenuOpen(false);
    setSelectedLabels([]);
  };

  const handleApplyButtonClick = () => {
    handleBulkLabelChange(selectedLabels);
    handleClose();
  };

  return (
    <>
      <LightTooltip title={t('addLabelsToMultipleContent')}>
        <IconButton
          data-testid='addLabelButton'
          disableRipple
          ref={addMultipleLabelsRef}
          className={classes.bulkIcon}
          onClick={() => {
            setIsLabelMenuOpen(true);
          }}>
          <FontAwesomeIcon
            icon={faTag}
            color={theme.palette.primary.main}
            width='13px'
            height='20px'
          />
        </IconButton>
      </LightTooltip>

      <Menu open={isLabelMenuOpen} anchorEl={addMultipleLabelsRef.current} onClose={handleClose}>
        <LabelMenu
          selectedLabels={selectedLabels}
          setSelectedLabels={setSelectedLabels}
          showBackButtonItem={false}
          showApplyAndClearAllButtons
          applyMultiLabelSelection={handleApplyButtonClick}
        />
      </Menu>
    </>
  );
};

AddLabelMenu.propTypes = {
  handleBulkLabelChange: PropTypes.func
};
